import React, { useEffect, useState } from 'react'
import FreeFieldDisplay from './FreeFielDisplay'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../../reducers/Dispatchers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import '../../../assets/smart-table.scss'

const STANDART_ERROR_MESSAGE = 'There is no Free Fields linked to this student'
const NO_FREE_FIELDS_ERROR_MESSAGE = "Your institution doesn't have Free Fields"
const ADDING_BUTTON_LABEL = 'Add Free Field'

const FreeFields = (props) => {
  const [errorMessage, setErrorMessage] = useState(STANDART_ERROR_MESSAGE)

  useEffect(() => {
    props.fetchInstitutionFieldsTypes(props.getUser)

    setErrorMessage(STANDART_ERROR_MESSAGE)
  }, [Object.keys(props.getInstititionFieldsTypes === undefined ? {} : props.getInstititionFieldsTypes).length])

  const addNewStudentFreeField = () => {
    if (Object.keys(props.getInstititionFieldsTypes).length === 0) {
      setErrorMessage(NO_FREE_FIELDS_ERROR_MESSAGE)
    } else {
      props.addNewStudentFreeField(props.studentId, props.getInstititionFieldsTypes[getAvailableFreeFieldsType()[0]], '', props.getUser, props.getActiveLanguage)
    }
  }

  const getAvailableFreeFieldsType = () => {
    // TODO : Refactor : same condition than renderFreeFieldsTypeMenu in FreeFieldDisplay Component
    const unavailableFreeFieldsTypes = Object.keys(props.getStudentFreeFields).map(key => {
      return props.getStudentFreeFields[key].freeFieldsTypeId
    })
    const availableInstitutionFreeFields = Object.keys(props.getInstititionFieldsTypes).filter(key => {
      return !unavailableFreeFieldsTypes.includes(props.getInstititionFieldsTypes[key].id) && props.getInstititionFieldsTypes[key].isEditable
    })

    return availableInstitutionFreeFields
  }

  const renderFreeFields = () => {
    const orderedStudentFreeFieldsKeys = Object.keys(props.getStudentFreeFields)

    orderedStudentFreeFieldsKeys.sort((a, b) => { return props.getStudentFreeFields[a].freeFieldsLabel.localeCompare(props.getStudentFreeFields[b].freeFieldsLabel) })

    return orderedStudentFreeFieldsKeys.map(key => {
      return (<FreeFieldDisplay key={key} id={key} />)
    })
  }

  const renderBody = () => {
    // display a message if the student dont have studentFreeFields.
    if (Object.keys(props.getStudentFreeFields).length === 0) {
      return (
        <div>
          {props.t(errorMessage)}
        </div>
      )
    } else {
      return (
        <table>
          <thead />
          <tbody>
            {renderFreeFields()}
          </tbody>
          <tfoot />
        </table>
      )
    }
  }

  return (
    <div className='flex-column smart-table'>
      <div className='flex-row'>
        <h3> Free Fields </h3>
        <div className='flex-fill' />
        <Button
          type='primary'
          onClick={addNewStudentFreeField}
          disabled={props.getInstititionFieldsTypes === undefined || getAvailableFreeFieldsType().length === 0}
        >
          <FontAwesomeIcon key='button' icon='plus' />
          &nbsp;
          {props.t(ADDING_BUTTON_LABEL)}
        </Button>
      </div>
      {renderBody()}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(FreeFields)
