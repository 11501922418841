export const COLORS_PALETTE = {
  FIRST: '#3787BF',
  SECOND: '#3c8dbc',
  THIRD: 'TBD',
  FOURTH: 'TBD',
  FIFTH: 'TBD',
  GREEN: '#8BC34A'
}

export const HOSPITAL_DEPARTMENT_COLORS = [
  {
    name: 'DEEP PURPLE',
    value:'#6C08EC'
  },
  {
    name: 'FOREST GREEN',
    value:'#009D2C'
  },
  {
    name: 'PASTEL BLUE',
    value:'#08C4EC'
  },
  {
    name: 'TERRACOTTA ORANGE',
    value:'#FF9536'
  },
  {
    name: 'PALE PINK',
    value:'#FFA6F6'
  },
  {
    name: 'OCEAN BLUE',
    value:'#1B98F0'
  },
  {
    name: 'SANDY YELLOW',
    value:'#FFB73D'
  },
  {
    name: 'PLUM VIOLET',
    value:'#8E44AD'
  },
  {
    name: 'SOFT BLUE',
    value:'#3498DB'
  },
  {
    name: 'SOFT GREEN',
    value:'#27AE60'
  },
  {
    name: 'EARTHY RED',
    value:'#E74C3C'
  },
  {
    name: 'MUSTARD YELLOW',
    value:'#F1C40F'
  },
  {
    name: 'DARK SLATE BLUE',
    value:'#34495E'
  },
  {
    name: 'SMOKY GRAY',
    value:'#7F8C8D'
  },
  {
    name: 'DEEP MATTE BLUE',
    value:'#2980B9'
  },
  {
    name: 'BRICK RED',
    value:'#C0392B'
  },
  {
    name: 'SOFT LAVENDER',
    value:'#9B59B6'
  },
  {
    name: 'AQUA GREEN',
    value:'#16A085'
  },
  {
    name: 'SPICY ORANGE',
    value:'#F39C12'
  },
  {
    name: 'DARK TERRACOTTA',
    value:'#D35400'
  },
  {
    name: 'MINTY GREEN',
    value:'#2ECC71'
  },
  {
    name: 'GRAYISH MAUVE',
    value:'#9C27B0'
  },
  {
    name: 'SOFT INDIGO',
    value:'#673AB7'
  },
  {
    name: 'MINERAL BLUE',
    value:'#3F51B5'
  },
  {
    name: 'TURQUOISE GREEN',
    value:'#1ABC9C'
  },
]

