import React, { useEffect, useState } from 'react'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../../reducers/Dispatchers'
import { Input, Menu, Dropdown, Tooltip, Popconfirm, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../../assets/free-field-manager.scss'
import { getLanguageByLocale } from '../../../utils'

const NO_FREE_FIELDS_ERROR_MESSAGE = "Your institution doesn't have Free Fields"
const DELETION_TOOLTIP_MESSAGE = 'Delete this data ?'

const FreeFieldDisplay = (props) => {
  const [studentFreeField, setStudentFreeField] = useState({})

  useEffect(() => {
    if (props.id != null) {
      setStudentFreeField(props.getStudentFreeFields[props.id])
    }
  }, [props.id, props.getStudentFreeFields])

  const onFreeFieldDeleted = (id) => {
    props.deleteStudentFreeFields(id, props.getUser)
  }

  const onFreeFieldTypeUpdate = (freeFieldsType) => {
    const newStudentFreeField = {
      ...studentFreeField,
      freeFieldsTypeId: freeFieldsType.id,
      freeFieldsLabel: freeFieldsType.translations[getLanguageByLocale(props.getActiveLanguage)],
      isEditable: freeFieldsType.isEditable,
      isBadgeDisplayed: freeFieldsType.isBadgeDisplayed,
      isEncryption: freeFieldsType.isEncryption
    }

    setStudentFreeField(newStudentFreeField)
    props.updateStudentFreeField(newStudentFreeField, props.getUser)
  }

  const onFieldValueEdit = newValue => {
    const newStudentFreeField = { ...studentFreeField, fieldValue: newValue }

    setStudentFreeField(newStudentFreeField)
  }

  const onBlur = (target) => {
    props.updateStudentFreeField(studentFreeField, props.getUser)
  }

  const renderNoAvailableFreeFieldsTypes = () => {
    return (
      <Menu.Item key={1}>
        {props.t(NO_FREE_FIELDS_ERROR_MESSAGE)}
      </Menu.Item>
    )
  }

  const renderDataDeletionButton = () => {
    if (studentFreeField.isEditable) {
      return (
        <Tooltip placement='top' title={props.t(DELETION_TOOLTIP_MESSAGE)}>
          <Popconfirm
            placement='top'
            okType='danger'
            title={props.t(DELETION_TOOLTIP_MESSAGE)}
            okText={props.t('Yes')}
            cancelText={props.t('Cancel')}
            onConfirm={() => onFreeFieldDeleted(studentFreeField.id)}
          >
            <Button
              type='danger'
              size='small'
              style={{ width: '28px', marginLeft: '2px', marginTop: '3px' }}
            >
              <FontAwesomeIcon icon='trash' />
            </Button>
          </Popconfirm>
        </Tooltip>
      )
    }

    return (
      <Button
        type='ghost'
        size='small'
        style={{ width: '28px', marginLeft: '2px', marginTop: '3px' }}
        disabled
      >
        <FontAwesomeIcon icon='trash' />
      </Button>
    )
  }

  const renderFreeFieldsTypesMenu = () => {
    const unavailableFreeFieldsTypes = Object.keys(props.getStudentFreeFields).map(key => {
      return props.getStudentFreeFields[key].freeFieldsTypeId
    })
    const menuBody = props.getInstititionFieldsTypes === undefined
      ? renderNoAvailableFreeFieldsTypes()
    // TODO : Refactor : same condition than addNewStudentFreeField in FreeFields/Index Component
      : Object.keys(props.getInstititionFieldsTypes).filter(key => {
        return !unavailableFreeFieldsTypes.includes(props.getInstititionFieldsTypes[key].id) && props.getInstititionFieldsTypes[key].isEditable
      }).map(key => {
        return (
          <Menu.Item key={key} onClick={() => onFreeFieldTypeUpdate(props.getInstititionFieldsTypes[key])}>
            {props.getInstititionFieldsTypes[key].translations[getLanguageByLocale(props.getActiveLanguage)]}
          </Menu.Item>
        )
      })

    return (
      <Menu>
        {menuBody}
      </Menu>
    )
  }

  return (
    <tr>
      <td className={!studentFreeField.isEditable ? 'not-allowed' : 'onMouseClickable'}>
        <Dropdown overlay={renderFreeFieldsTypesMenu()} trigger={['click']} disabled={!studentFreeField.isEditable}>
          <div>
            {studentFreeField.freeFieldsLabel}
          </div>
        </Dropdown>
      </td>
      <td>
        <div className='flex-row'>
          <Input
            className='inputText'
            value={!studentFreeField.isEncryption ? studentFreeField.fieldValue : '********'}
            placeholder={props.t('No value')}
            onChange={e => onFieldValueEdit(e.target.value)}
            onBlur={e => { onBlur(e.target) }}
            disabled={!studentFreeField.isEditable}
          />
          {renderDataDeletionButton()}
        </div>
      </td>
    </tr>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(FreeFieldDisplay)
