import { internshipStates, QUOTAS_PER_SECTOR } from './constants'

const QUOTA_TYPE = {
  FREE_STUDENT: 'FREE_STUDENT',
  OTHERS: 'OTHERS'
}

/**
 * This method returns the total quotas list of the internship's sector in institution
 * if display_quotas_per_sector institution option is true, else it return null
 * @param {*} internship
 * @param {*} institutions
 */
const getInternshipInstitutionSectorQuotas = (internship, institutions, schools) => {
  const institution = institutions.find(item => item.id === internship.institution)
  if (!institution) {
    return null
  }
  // check if the option is turned on
  if (institution.institutionOptions && institution.institutionOptions.find(item => item.optionType === QUOTAS_PER_SECTOR && item.optionEnabled === true)) {
    // get the sector of the institution and sum all the quotas
    const sector = institution.sectors.find(item => item.id === internship.sector)
    let quotasValue = null
    const userSchoolsIds = schools.map(item => item.id)
    if (sector && sector.quotas) {
      sector.quotas.forEach(item => {
        if (!['FREE_STUDENT', 'OTHERS'].includes(item.type) && userSchoolsIds.includes(item.school)) {
          if (quotasValue === null) {
            quotasValue = item.total
          } else {
            quotasValue += item.total
          }
        }
      })

      const freeStudentQuota = sector.quotas.find(item => item.type === QUOTA_TYPE.FREE_STUDENT)
      const othersQuota = sector.quotas.find(item => item.type === QUOTA_TYPE.OTHERS)
      if (freeStudentQuota) {
        quotasValue += freeStudentQuota.total
      }
      if (othersQuota) {
        quotasValue += othersQuota.total
      }
    }
    return quotasValue
  } else {
    return null
  }
}

/**
 * This converts the internship state from a numeric value to human readable text
 */
const getInternshipStateText = stateId => {
  let internshipState = null
  for (const stateName in internshipStates) {
    if (internshipStates[stateName] === stateId) {
      const loweredCaseState = stateName.toLowerCase()
      internshipState = `${loweredCaseState.charAt(0).toUpperCase()}${loweredCaseState.slice(1)}`
      break
    }
  }
  return internshipState
}

export {
  getInternshipInstitutionSectorQuotas,
  getInternshipStateText
}
