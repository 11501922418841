import React, { useContext, useMemo } from 'react'

import DataTable from '../../../../Components/shared/DataTable/DataTable'
import Cookies from 'js-cookie'
import { ORDER_BY } from '../../../../utils/constants'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getColor, getManagerLabel, getName, HospitalDepartment } from '../../../../utils/entities/hospitalDepartment'
import { HospitalDepartmentModalContext } from '../../../../Providers/Forms/HospitalDepartmentModal'
import { DeleteAction, DisplayDepartmentSectors, EditHospitalDepartment } from '../../../../Components/shared/DataTable/utils/actions'
import { HospitalDepartmentSectorsDrawerContext } from '../../../../Providers/Drawer/HospitalDepartmentSectorsDrawer'
import { Tag } from 'antd'
import { getLuminanceFromColorCode } from '../../../../utils/colors'

const DEFAULT_COLOR = '#A3A3A4'

const mapStateToProps = state => ({ t: getTranslate(state.locale) })

const HospitalDepartmentsTable = ({ data, institution, loading, metadata, parameters, onDelete, onParametersChange, onReload, onLinkChange, t }) => {
  const { setSelectedHospitalDepartment } = useContext(HospitalDepartmentModalContext)
  const hdd = useContext(HospitalDepartmentSectorsDrawerContext)

  const maxHeight = useMemo(() => `calc(100vh - ${Cookies.get('switch_user_id') ? '230px' : '189px'})`, [Cookies.get('switch_user_id')])
  const columns = useMemo(() => [
    { title: t('data_table.column_title.name'), orderBy: ORDER_BY.NAME, key: getName },
    { title: t('data_table.column_title.manager'), orderBy: ORDER_BY.MANAGER, key: getManagerLabel, style: { maxWidth: '200px' } },
    {
      title: t('data_table.column_title.color'),
      key: h => (
        <Tag color={getColor(h) ?? DEFAULT_COLOR} style={{ color: getLuminanceFromColorCode(getColor(h) ?? DEFAULT_COLOR) }}>
          {t(getColor(h) ?? DEFAULT_COLOR)}
        </Tag>
      )
    }
  ], [t])
  const actions = useMemo(() => [
    new EditHospitalDepartment({ onClick: h => setSelectedHospitalDepartment({ data: h, callback: onReload }) }).build(),
    new DisplayDepartmentSectors({ onClick: h => hdd.setSelectedHospitalDepartment({ data: h, callback: onLinkChange }) }).build(),
    new DeleteAction({ onDelete })
  ], [onDelete, onReload, setSelectedHospitalDepartment, hdd.setSelectedHospitalDepartment])
  const externalActions = useMemo(() => [], [])

  return (
    <div style={{ height: maxHeight }}>
      <DataTable
        actions={actions}
        columns={columns}
        data={data}
        externalActions={externalActions}
        loading={loading}
        metadata={metadata}
        parameters={parameters}
        onCreate={() => setSelectedHospitalDepartment({ data: new HospitalDepartment({ institution }), callback: onReload })}
        onParametersChange={onParametersChange}
      />
    </div>
  )
}

export default connect(mapStateToProps)(HospitalDepartmentsTable)
